/* ==========================================================================
|  FONCTIONS
========================================================================== */


@function calculateRem($size) {
	$remSize: $size / 10px;
	@return $remSize * 1rem;
}



@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
	line-height: calculateRem($size+10);
}

@mixin radius($radius) {
	border-radius: $radius;
}

@media screen and (min-width: 768px) {
	.col-md-30 { width: 30%; }
	.col-mdS-70 { width: 70%; }
}

@media screen and (max-width: 1023px) {


	.node-type-page-d-accueil {
		#page {
			#main-content {
				#content-wrapper {

					.pour_habiter .view-lodgim-liste-terrains .slick__slider .slick-list .slide__content .terrains span.titre-terrains{ @include font-size(16px); }
				}

			}
		}
	}

	.node-type-page-d-accueil {
		#page {
			#main-content {
				.slick--view--lodgim-liste-terrains .slick-slide{ /* width: auto !important; */ }
			}
		}
	}

	body{

		h1{@include font-size(48px);}
		h2{@include font-size(36px);
			&:after{top: 23px;}
		}
		h3{@include font-size(20px);}

		#fixe_overlay{z-index: 1001;}

		.header_site{z-index: 1000;}
		#header{height: 70px;
			#logo{
				a img{max-height: 70px; padding: 10px 0;}
			}
		}
		#main-navigation{  position: absolute; z-index: 20; top: 47px; width:100px; height: 60px; right: 0; background: none; border: none; box-shadow:none;
			#main-navigation-inside{width:100px; float: none; background: none;
				#block-search-form{display: none!important;}
				.region-navigation .block-tb-megamenu .tb-megamenu{display: block; width: auto;
					button{float: right; background: #FFF; padding: 10px 16px; margin-right: 30px;
						i{color: $couleur_base; font-size: 30px;}
					}
					.nav-collapse{top: 110px; width: 100%; left: 0; position: fixed; margin: 0; z-index: 100;

						> ul {border-bottom: 7px solid $couleur_base; background: #FFF;
							> li{display: block; text-align: left;
								.tb-megamenu-submenu{background: $couleur_base_opacity2; border: none;
									.tb-megamenu-column-inner{
										li a{margin: 5px 20px; color: #333; background: none;}
									}
								}
							}
						}
						&.in{ height: auto!important; overflow-y: auto!important; }
					}
				}
			}
		}
		#header-inside:before{opacity: 0.2!important}


		#banner{margin-top: 110px;
			#block-views-lodgim-slide-accueil-block{
				.slide-accueil{
					li{
						.contenu-slide .slide-titre{@include font-size(48px);}
						img{object-fit:cover; height: 250px; width: 100%;}
					}
				}
			}
		}


		#fixe li span{display: none!important;}
	}


	.node-type-page-d-accueil {
		#page {
			#main-content {
				#content-wrapper {
					.pour_habiter {
						.view-lodgim-pour-habiter{ /*width: 100%; padding: 0 15px;*/
							.view-header{
								.img_right{display: none;}
								.content{width: 100%;}
							}
							.view-content{width: 100%;}
						}
					}
				}
			}
		}
	}


	body #section_after2 .view-lodgim-pour-nous-joindre .view-content .views-row .node-agence .group-contenu:before{width: 50px; height: 50px; margin-top: -50px;}
	body #section_after2 .view-lodgim-pour-nous-joindre .view-content .views-row:last-child .field{padding-left: 10px;}
	body #section_after2 .view-lodgim-pour-nous-joindre .view-content .views-row:last-child .field:before{display: block!important; margin: auto;}
	body #section_after3 #block-block-26 .sociaux .sociaux_txt{@include font-size(20px);}


	.not-front #page #main-content{margin-top: 110px;
		.field-name-breadcrumb .breadcrumb{display: none; /* padding: 10px 30px; */ }
		.group-banniere{height: 250px;
			img{object-fit:cover; height: 250px; width: 100%;}
		}

		.liste .views-row{width: 100%; float: none;}
		.liste .view-filters .views-exposed-widgets #edit-field-localisation-programme-locality-wrapper{width: 100%; display: block; padding: 10px 10% 0;
			label{width: 100%; padding: 0; text-align: center;}
			.views-widget{width: 100%;}
		}


		.liste .view-filters .views-exposed-widgets{
			.views-submit-button{width: 100%; display: block;}
			#edit-field-cogedim-fiscalite-tid-wrapper .form-type-select .form-item{width: 32%;}
		}
	}


	.node-type-cogedim-flux-content,
	.node-type-property{
		.field-name-breadcrumb .breadcrumb{padding: 10px 0!important}
		#block-system-main .ds-1col{
			section{
				.group-bloc-haut{
					.header-info{display: block;
						.field-slideshow-wrapper{display: block; width: 100%;
						}
						.group-header-info-right{display: block; width: 100%; margin-top: -20px; padding-bottom: 0;
							.group-contact-brochure{position: relative; bottom: auto}
							.btn-carr{ padding-top: 10px; position: relative; bottom: 0; }
						}
					}
				}
			}
			article {
				&.article-lodgim-terrain,
				&.article-lodgim-neuf{
					.vertical-tabs {
						.vertical-tabs-list{width: 100%; float: none; margin-bottom: 30px;

						}
						.vertical-tabs-panes{width: 100%; margin: auto;
							fieldset{padding: 0; }
							.group-tablots .modal-content{transform:scale(0.6);}
						}
					}
					.field-name-simulateur{position: relative; width: 100%; top: auto;}
				}
				&.article-lodgim-ancien{

					.field-name-simulateur{position: relative; width: 100%; top: auto;}
				}
			}
		}
	}


	.voir-aussi .jcarousel-container .jcarousel-clip .jcarousel-item .terrains .miniature-terrains{height: 200px;}

	.node-type-article,
	.node-type-page {
		#content-wrapper {
			.group-contenu {
				.field-name-body{padding: 0 20px;}
			}
		}
	}


	.liste-actu .view-content .views-row{width: 50%;}

	#sliding-popup .popup-content{ flex-wrap: wrap;
		img{ max-height: 90px; max-width: 170px; }
		#popup-buttons { padding-left: 0; }
	}

}

@media screen and (max-width: 991px) and (min-width: 480px) {

	.node-type-page-d-accueil {
		#page {
			#main-content {
				#content-wrapper {
					.pour_habiter .view-lodgim-liste-terrains .slick__slider .slick-list .slide__content .terrains {
						span.titre-terrains{ position: inherit; min-height: 79px; width: 100%; }
						span.info_supp{ position: inherit; @include font-size(13px); min-height: 55px; display: block; }
					}
				}

			}
		}
	}

}

@media screen and (max-width: 767px) {

	.not-front #page #main-content{margin-top: 70px;}

	.node-type-page-d-accueil.domain-www-lodgim-immobilier-fr #banner #banner-inside .slide-accueil li .contenu-slide .slide-titre{@include font-size(28px);}

	.node-type-page-d-accueil {
		#page {
			#main-content {
				#content-wrapper {
					.pour_vendre{ top: -450px!important; }
				}
			}
		}
	}

	body{@include font-size(14px);

		input[type="submit"],
		.btn{@include font-size(14px);
		}

		h1{@include font-size(36px);}
		h2{@include font-size(30px);
			&:after{top: 19px;}
		}
		h3{@include font-size(18px);}

		#header-top #header-top-left ul{padding: 0;
			li{margin-right: 10px; @include font-size(12px); margin-top: 8px;}
		}

		#banner{
			#block-views-lodgim-slide-accueil-block{
				.slide-accueil{
					li{
						.contenu-slide .slide-titre{@include font-size(36px);}
					}
				}
			}
		}

		#fixe{width: 50px;
			li{
				i{width: 50px; line-height: 50px; font-size: 24px;}
				.fa-envelope{font-size: 20px;}
			}
		}
		#footer{
			img{margin: auto;}
		}
 	}

	#page {
		#main-content {
			#section_after3 #block-block-26 .sociaux {
				.sociaux_txt{font-size: 20px;display: block; width: 100%; padding: 0 20px; line-height: 24px; margin-bottom: 15px;}
				.sociaux_picto{display: block; width: 100%;}
			}
			#section_after2 .view-lodgim-pour-nous-joindre .view-content .views-row{width: 100%; float: none;

				&:last-child .field{display: block; width: 100%; text-align: left;
					&:before{display: inline-block!important;}
				}
				.alerte{ background-image: -webkit-linear-gradient(30deg,#ed1c24 95%,#fff 97%); }
			}

		}
	}

	.liste {
		.views-row {
			.terrains{
				.info_supp{@include font-size(16px);}
			}
		}

		.view-filters .views-exposed-widgets{
			#edit-field-cogedim-fiscalite-tid-wrapper .form-type-select .form-item{width: 48%!important;}
			.views-widget-filter-secondary fieldset{
				legend{padding: 0; @include font-size(18px);}
				.fieldset-wrapper .form-item{width: 50%;}
				.fieldset-wrapper .form-item:nth-child(2n){padding-right: 0px;}
			}
		}
	}



	.node-type-cogedim-flux-content,
	.node-type-property{


		#block-system-main .ds-1col{
			section{
				.bg_blanc a{@include font-size(14px);}
				.bg_blanc a:before{ font-size: 14px; width: 20px;}
				.bg_blanc a:after{ font-size: 14px; width: 20px;}
				.bg_blanc a i:before{ font-size: 14px; width: 20px;}
				.bg_blanc #prevnext-previous{margin-right: 15px;}
				.group-bloc-haut{
					h2{width: 100%; }
					.field-name-field-certification{position: relative; margin-top: -70px; z-index: 100;}

					.header-info{
						.field-slideshow-wrapper{

							.field-slideshow{
								.field-slideshow-slide iframe{ max-width: 100%; height: auto; min-height: 186px;}
							}

							.field-slideshow-carousel-wrapper{ max-width: 296px;
								 .carousel-prev, .carousel-next{ display: none; }
							}
							.carte,
							&:after{display: none!important;}
						}

					}
				}
			}
			article {
				.vertical-tabs {
					.vertical-tabs-list{width: 100%; float: none; margin-bottom: 30px;

					}
					.vertical-tabs-panes{width: 100%; margin: auto;
						fieldset{padding: 0; }
						.group-tablots {
							.modal-content{transform:scale(0.3);}
							table{
								.views-field-field-plans-lot{
								    min-width: 90px;
								}
								/*
								.views-field-field-num-lot,
								.views-field-field-surf-planch-lot,
								.views-field-field-exposition-lot,
								.views-field-nothing-3{display: none;}
								*/

								.views-field-field-cogedim-lot-statut,
								.views-field-field-cogedim-lot-etage,
								.views-field-field-cogedim-surfaceannexehl{display: none;}
							}
						}
						.group-tabdocuments{
							table{
								th:last-child,
								td:last-child{display: none;}
							}
						}

					}
				}
				.field-name-simulateur{position: relative; width: 100%; top: auto;
					form .form-actions{margin-top: -54px;}
				}
			}
		}
	}
	#block-views-lodgim-liste-immo-block-1,
	#block-views-lodgim-liste-terrains-block-1{display: none!important}

	.liste-actu .view-content .views-row{width: 100%;}

}



@media screen and (max-width: 480px) {

	.node-type-page-d-accueil.domain-www-lodgim-immobilier-fr #banner #banner-inside .slide-accueil li .contenu-slide .slide-titre{@include font-size(20px);}
	#block-block-36 .col-md-4 .titre-bloc, #block-block-36 .col-md-3 .titre-bloc{height: auto;}

	.node-type-page-d-accueil {
		#page {
			#main-content {
				#content-wrapper {
					.pour_vendre{ top: -665px!important; }
					.field-name-field-body2{display: none;}
				}

				.slick--view--lodgim-liste-terrains .slick-slide{ width: 100%!important; }
			}
		}
	}

	body{
		h1{@include font-size(26px);}
		h2{@include font-size(22px);
			&:after{display: none; }
			span{display: block; padding: 0 15px;}
		}

		#header-top{display: none}

		#fixe_overlay{width: 100%; right: 100%;}

		#fixe{top: 120px;}

		#banner{margin-top: 70px;
			#block-views-lodgim-slide-accueil-block{
				.slide-accueil{
					li{
						.contenu-slide .slide-titre{@include font-size(26px); margin: 10px 0;}
					}
				}
			}
		}

		#main-navigation{top:7px;
			#main-navigation-inside{
				.region-navigation .block-tb-megamenu .tb-megamenu{
					.nav-collapse{top: 70px;}
				}
			}
		}
	}

	.node-type-cogedim-flux-content,
	.node-type-property{
		#block-system-main .ds-1col{

/*
			article {
				.vertical-tabs {
					.vertical-tabs-panes{
						fieldset{
							table{}
						}

						.group-tablots {overflow: hidden;
							table{ display: block; width: 100%; overflow-x: scroll;}
						}
					}
				}
			}
*/
			section{
				.group-bloc-haut{
					h2{@include font-size(24px);}

					.field-name-field-info-suppl-mentaire{ display:none; }
					.field-slideshow-wrapper .field-slideshow-controls{ display:none; }
				}


			}
		}
	}

	#sliding-popup .popup-content { padding: 25px 25px;
		.h3{ @include font-size(14px); }
	}
}

/* ALPHA */

.node-type-property .header-info .taxonomy-term-description{
	position: absolute;
    z-index: 100;
    right: 30px;
    margin-top: 15px;
    width: 80px;
}

@media screen and (min-width: 1350px) {
	// .container{ width: 1320px; }

	.page-node-88819{
		.wrapper-root{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			z-index: -1;
			position: fixed;
			align-items: center;
			touch-action: none;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.85);
			-webkit-tap-highlight-color: transparent;
		}

		&.isactive { overflow: hidden;
			.presentation-wrapper{
				.wrapper-root{ z-index: 510; }
			}
		}

		#ajax_target .view-details-lot .panel{
			left: auto;
			right: 0;
			top: 0;
			flex: 1 0 auto;
			height: 100%;
			display: flex;
			outline: 0;
			z-index: 1200;
			position: fixed;
			overflow-y: auto;
			flex-direction: column;
			-webkit-overflow-scrolling: touch;
			border-radius: 15px 0 0 15px;
			border: 0;
			box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
			width: 66.66667%;
			transition:all .08s linear;

			.panel-body{
				background: linear-gradient(#018c92, #fff);
				.views-field-nothing-6{
					.jumbotron{
						border-bottom-left-radius: 0;
						border-top-left-radius: 30px;
						box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
						padding-top: 15px;
    					padding-bottom: 15px;

						h3{
							padding: 15px;
						    background: #e4741e;
						    font-weight: bold;
						    color: #fff;
							display: inline-block;
							border-radius: 30px;
							span{ font-weight: 400; @include font-size(18px); }
						}
						.certif_bien{
						    display: flex;
							flex-direction: row;
							.vocabulary-fiscalite { height: 125px; width: 125px;
								img{ padding: 10px; }
							}
						}
						.surfaces-bien{ @include font-size(20px); padding: 15px 0; }
					}
					.boutons-bien{ padding-bottom: 15px; }
					.prix-bien{
						@include font-size(20px);
						padding: 3px 10px;
						border: 3px solid #e4741e;
						border-radius: 30px;
						display: inline;
						span{ font-weight: 800; }
					}
				}

				button.close{
						position: fixed;
					    right: 55px;
					    z-index: 9999;
					    top: 25px;
					span{
						font-size: 50px;
						background: #fff;
					    border-radius: 30px;
						border: 1px solid #018c92;
					    width: 50px;
					    height: 50px;
					    display: block;
					    color: #018c92;
					}
				}
			}
		}
		.article-lodgim-terrain{
			ul.vertical-tabs-list{
				position: fixed;
			    top: 50%;
			    left: 0;
			    // width: auto!important;
				width: calc( (100% - 1290px) / 2)!important;
				z-index: 500;
				li a strong{  }
				.vertical-tab-button.selected{ background: #018c92!important; }
			}
			.vertical-tabs-panes{ width: 100%!important; margin: 0!important;
				.view-liste-des-lots-detaillee{
					.views-table tr {
						th:first-child, td:first-child{ display: none; }
					}
				}
			}
		}
	}
	.vertical-tabs-panes{  /* TEMP */
		.view-liste-des-lots-detaillee{
			.views-table tr {
				th:first-child, td:first-child{ display: none; }
			}
		}
	}
}

@media (max-width: 1349px){

	.page-node-88819{
		#ajax_target .view-details-lot .panel{
			left: auto;
			right: 0;
			top: 0;
			flex: 1 0 auto;
			height: 100%;
			display: flex;
			outline: 0;
			z-index: 1200;
			position: fixed;
			overflow-y: auto;
			flex-direction: column;
			-webkit-overflow-scrolling: touch;
			border-radius: 15px 0 0 15px;
			border: 0;
			box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
			width: 100%;
			transition:all .08s linear;

			.panel-body{
				background: linear-gradient(#018c92, #fff);
				.views-field-nothing-6{
					.jumbotron{
						border-bottom-left-radius: 0;
						border-top-left-radius: 30px;
						box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
						padding-top: 15px;
    					padding-bottom: 15px;

						h3{
							padding: 15px;
						    background: #e4741e;
						    font-weight: bold;
						    color: #fff;
							display: inline-block;
							border-radius: 30px;
							span{ font-weight: 400; @include font-size(18px); }
						}

						.certif_bien{
						    display: flex;
							flex-direction: row;
							.vocabulary-fiscalite { height: 125px; width: 125px;
								img{ padding: 10px; }
							}
						}
						.surfaces-bien{ @include font-size(20px); padding: 15px 0; }
					}
					.boutons-bien{ padding-bottom: 15px; }
					.prix-bien{
						@include font-size(20px);
						padding: 3px 10px;
						border: 3px solid #e4741e;
						border-radius: 30px;
						display: inline;
						span{ font-weight: 800; }
					}
				}

				button.close{
						position: fixed;
					    right: 55px;
					    z-index: 9999;
					    top: 25px;
					span{
						font-size: 50px;
						background: #fff;
					    border-radius: 30px;
						border: 1px solid #018c92;
					    width: 50px;
					    height: 50px;
					    display: block;
					    color: #018c92;
					}
				}
			}
		}
		.article-lodgim-terrain{
			ul.vertical-tabs-list{
				display: flex!important;
				flex-direction: row;
				background-color: #fff;
				overflow: hidden;
				position: fixed;
				bottom: 0;
				left: 0;
				z-index: 500;
				width: 100%!important;
				margin-bottom: 0!important;
				border-top: none;
				.vertical-tab-button{ margin: 0; width: 25%!important; text-align: center!important;
					&.selected{ background: #018c92!important; width: 25%!important; }
					a{ display: inline-block; height: 96px;
						&:before{ display: block!important; width: auto!important; }
						strong{ display: block; }
					}
				}
			}
			.vertical-tabs-panes{ width: 100%!important; margin: 0!important;
				.view-liste-des-lots-detaillee{
					.views-table { border: hidden; border-collapse: separate; border-spacing: 0 40px;
						thead{display: none;}
						tbody tr{
							th:first-child, td:first-child{ display: block; } /* TEMP */
							td{
								display: block;
								text-align: left;
								width: 100%!important;
								height: auto;
								&:first-child{ text-align: center; color: #fff; background: #018c92; @include font-size(20px); }
								&:before {
									content: attr(data-label);
									float: left;
									font-weight: 700;
									width: 65%;
			    					text-align: left;
								}
								&.views-field-nothing-3, &.views-field-views-conditional{
									width: auto!important;
									display: inline-block;
									border: none;
									a{@include font-size(16px);}
								}
								.inline-block {
								    -webkit-box-align: left;
								    -ms-flex-align: left;
								    align-items: left;
								    -webkit-box-pack: left;
								    -ms-flex-pack: left;
								    justify-content: left;
								}
							}
						}
					}
				}
			}
		}
	}

	.vertical-tabs-panes{  /* TEMP */
		.view-liste-des-lots-detaillee{
			.views-table tr {
				th:first-child, td:first-child{ display: none; }
			}
		}
	}

}

@media screen and (max-width: 768px) {
	.page-node-88819{
		#ajax_target .view-details-lot .panel{
			.panel-body{
				.boutons-bien .btn{ @include font-size(13px); }
			}
		}
	}
}

@media screen and (max-width: 480px) {

}

.page-node-88819{
	.vertical-tabs-panes{
		h2{
			width: 99.225vw;
			position: relative;
			margin-left: -49.59vw;
			left: 49%;
		}
		.view-liste-des-lots-detaillee{
			td.views-field-field-prix-lot{
				font-weight: 800;
    			color: $orange;
				@include font-size(18px);
			}
		}
	}

	.btn3d {
	    transition:all .08s linear;
	    position:relative;
	    outline:medium none;
	    -moz-outline-style:none;
	    border:0px;
	    /* margin-right: 10px; */
		/* margin-top: 5px; */
	    margin-bottom: 15px;
	}
	.btn3d:focus {
	    outline:medium none;
	    -moz-outline-style:none;
	}
	.btn3d:active {
	    top:9px;
		position: relative;
	}
	.btn-default {
	    box-shadow:0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255,255,255,0.15) inset, 0 8px 0 0 #adadad, 0 8px 0 1px rgba(0,0,0,0.4), 0 8px 8px 1px rgba(0,0,0,0.5);
	    background-color:#fff;
	}
	.btn-primary {
	    box-shadow:0 0 0 1px #4c9ca0 inset, 0 0 0 2px rgba(255,255,255,0.15) inset, 0 8px 0 0 #018c92, 0 8px 0 1px rgba(0,0,0,0.4), 0 8px 8px 1px rgba(0,0,0,0.5);
	    background-color:#4c9ca0;
	}
	 .btn-success {
	    box-shadow:0 0 0 1px #5cb85c inset, 0 0 0 2px rgba(255,255,255,0.15) inset, 0 8px 0 0 #4cae4c, 0 8px 0 1px rgba(0,0,0,0.4), 0 8px 8px 1px rgba(0,0,0,0.5);
	    background-color:#5cb85c;
	}
	 .btn-info {
	    box-shadow:0 0 0 1px #5bc0de inset, 0 0 0 2px rgba(255,255,255,0.15) inset, 0 8px 0 0 #46b8da, 0 8px 0 1px rgba(0,0,0,0.4), 0 8px 8px 1px rgba(0,0,0,0.5);
	    background-color:#5bc0de;
	}
	.btn-warning {
	    box-shadow:0 0 0 1px #f0ad4e inset, 0 0 0 2px rgba(255,255,255,0.15) inset, 0 8px 0 0 $orange, 0 8px 0 1px rgba(0,0,0,0.4), 0 8px 8px 1px rgba(0,0,0,0.5);
	    background-color:#f0ad4e;
	}
	.btn-danger {
	    box-shadow:0 0 0 1px #c63702 inset, 0 0 0 2px rgba(255,255,255,0.15) inset, 0 8px 0 0 #C24032, 0 8px 0 1px rgba(0,0,0,0.4), 0 8px 8px 1px rgba(0,0,0,0.5);
	    background-color:#c63702;
	}
}
